<template>
    <div class="modal_photo_menu">
        <div v-if="data_plate" class="body-menu p-relative">
            <div class="content_menu">
                <div class="content-head">
                    <h4 class="text-light text-center text-uppercase f-quezon">
                        {{data_plate.name}}
                    </h4>
                    <button class="text-light close-menu" v-on:click="close_template_photo_menu" style="background:none">
                        <img src="../../assets/x.svg" alt="" width="15" style="filter: invert(1)">
                    </button>
                </div>
                
                <div :class="footer_view?'bg-footer-photo':'bg-transparent'" class="content-footer">
                   <div class="w-100 text-center">
                        <button style="" @click="toggle_footer" :class="footer_view?'rotate-0':'rotate-180'" class="mb-3 text-center btn-footer-view bg-transparent">
                            <svg width="25" height="15" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
                                <path class="path" d="M0,0 l35,50 l35,-50" fill="none" stroke="white" stroke-linecap="round" stroke-width="5" />
                            </svg>
                        </button>
                   </div>
                    <!-- <p class="text-light mb-0">{{data_plate.description}}</p>
                    <p class="text-light mb-0">s/{{data_plate.price}}</p>
                    <p v-if="data_plate.ABV" class="text-light mb-0">ABV: {{data_plate.ABV}}</p>
                    <p v-if="data_plate.IBU" class="text-light mb-0">IBU: {{data_plate.IBU}}</p> -->


                    <!-- <h5 class="decorated text-uppercase text-light" v-if="data_plate.subgroup">
                        {{data_plate.name}}
                    </h5> -->
                    




                    <!-- Descripcion  del plato -->
                    <transition name="fade">
                        <div v-if="footer_view">
                            <p v-if="data_plate.description" class="text-light">{{data_plate.description}}</p>
                            <span class="text-light">{{ data_plate.subgroup }}</span>
                            <p class="text-light">{{data_plate.subgroup_description}}</p>
                            <p class="text-light" :class="data_plate.ABV || data_plate.description?'mb-0':''">
                                <span class="precio-photo">{{data_plate.price}} 
                                    <span v-show="data_plate.price_tinta && data_plate.category_id !== 34">
                                        <img class="img-unset" style="filter:invert(100%);" src="../../assets/tinta.svg" height="15px" alt="">&nbsp;&nbsp;&nbsp;&nbsp;s/{{ data_plate.price_tinta }} 
                                        <img class="img-unset" style="filter:invert(100%);" src="../../assets/media-tinta.svg" height="15px" alt="">
                                    </span> 
                                        <span v-show="data_plate.price_tinta && data_plate.category_id == 34">
                                        <img class="img-unset" style="filter:invert(100%);" src="../../assets/botelladevino.svg" height="15px" alt="">&nbsp;&nbsp;&nbsp;&nbsp;s/{{ data_plate.price_tinta }}
                                        <img class="img-unset" style="filter:invert(100%);" src="../../assets/copadevino.svg" height="15px" alt="">
                                    </span> 
                                </span>
                                <!--<span>{{plate.allergens}}</span>-->
                            </p>

                            <small v-show="data_plate.ABV && data_plate.IBU" class="span-ABV margin-left-1 text-light">
                                <span v-if="data_plate.ABV">
                                    ABV: {{data_plate.ABV}}
                                </span>
                                    <span v-if="data_plate.IBU && data_plate.ABV">
                                        - IBU: {{data_plate.IBU}}
                                    </span>
                            </small>
                        



                    <!-- Mostrar  alergenos aqui -->
                        <div v-if="data_plate.allergens">
                            <div class="">
                                <!--<p class="text-center text-uppercase pt-3">Contiene los siguientes alergenos</p>-->
                                <!--<button > active</button>-->
                                
                                <div class="d-flex-icon">
                                    <div v-for="alergeno in allergens" :key="alergeno.id"
                                    :class="consult_ids({alerg_id:alergeno.id,ids_allergens:data_plate.allergens})?'':'d-none'"
                                     class="item-flex">
                                        <img v-if="consult_ids({alerg_id:alergeno.id,ids_allergens:data_plate.allergens})" 
                                        
                                        v-on:click="toggle_btn_allergen" class="img-icon" 
                                        :src="require('../../assets/icon/' + alergeno.icon_name + '.svg')" 
                                        :alt="alergeno.name" aria-hidden="true" data-toggle="tooltip" :title="alergeno.icon_name">
                                        <transition v-if="consult_ids({alerg_id:alergeno.id,ids_allergens:data_plate.allergens})" 
                                        
                                        name="fade">
                                            <span class="text-light" v-if="toggle_allergen">{{alergeno.name}}</span>    
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </transition>

                </div>
            </div>

             <ImageItem
                v-scrollanimation
                class="img-photo-menu"
                v-if="data_plate"
                :source="data_plate.photo.slug"
            />
            <img class="img-photo-menu" v-if="data_plate" :src="data_plate.photo.slug" alt=""> 
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'

export default {
    props:['data_plate','allergens'],
    emits:['toggle_photo_menu'],

    setup(props,{emit}){
        const footer_view = ref(false)
        const toggle_allergen = ref(true)

        const toggle_footer = () => {
            footer_view.value = !footer_view.value
        }

        const close_template_photo_menu = () => {
            
            // if (document.exitFullscreen) {
            //     document.exitFullscreen();
            // } else if (document.webkitExitFullscreen) { /* Safari */
            //     document.webkitExitFullscreen();
            // } else if (document.msExitFullscreen) { /* IE11 */
            //     document.msExitFullscreen();
            // }
            emit('toggle_photo_menu',false);
        }

        const toggle_btn_allergen = () => {
            toggle_allergen.value = !toggle_allergen.value
        }

        const consult_ids = (play) => {
            let hola = null;
            //ids_allergens.map()
            if(play.ids_allergens){
                play.ids_allergens.map(function(alergeno){
                    //console.log(alergeno)
                    //console.log(play.alerg_id)
                    if(alergeno == play.alerg_id){
                        hola = 1;
                    }
                });    
            }
            return hola;
        }

        return{
            footer_view,
            toggle_allergen,
            toggle_footer,
            close_template_photo_menu,
            toggle_btn_allergen,
            consult_ids,
        }
    },
    
}
</script>
<style lang="scss" scoped>
.modal_photo_menu{
    position: fixed;
    z-index: 99999999;
    background: #000000bb;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    
}
.body-menu{
    height: 100%;
    width: 500px;
    background:#19282b; 
    @media screen and (max-width: 576px) {
        width: 100vw;
        height: 100%;
    }
     @media screen and (max-width: 500px) {
        height: 100%;
    }
}
.content_menu{
    position: relative;
    z-index: 2;
    height: 100%;
    //padding: 15px;
}
.content-footer{
    transition: all 0.3s ease;
    padding:15px;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.bg-footer-photo{
    background: linear-gradient(360deg, #091103, rgba(0, 0, 0, 0.7) ,rgba(0, 0, 0, 0.5),   rgba(255, 255, 255, 0));
}
.content-head{
    padding:25px 15px 15px 15px;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, #091103, rgba(255, 255, 255, 0));
}
.close-menu{
    position: absolute;
    top:15px;
    right: 15px;
    font-weight: bold;
    font-size: 20px;
}
.rotate-180{
    transform: rotate(180deg);
}
.rotate-0{
    transform: rotate(0deg);
}
.btn-footer-view{
    transition: all 0.3s;
    svg .path{
        box-shadow: 3px 3px 3px black;
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    }
}
button:focus{
    outline: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
  /*
    This classes are for the directive.
    For each element observed by our directive, the before-enter class is added.
  */
   .before-enter {
    opacity: 0;
    transform: scale(.9);
    transition: all 1s ease-out;
  }
  /*
    If the element intersects with the viewport, the before-enter class is added.
  */
  .enter {
    opacity: 1;
    transform: translateX(0px);
  }
    .modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}
.text-light{
    font-weight: 300;
}
</style>