<template>
    <transition name="fade">
        <div class="container-fluid bg-greenpoint-light-v1 py-4">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div v-for="alergeno in allergens" :key="alergeno.id">
                            <div v-on:click="active_toggle(alergeno.id)" class="bar-title">
                                <div class="item-title">
                                    <img :src="require('../../assets/icon/' + alergeno.icon_name + '.svg')" width="45" :alt="alergeno.name" >
                                </div>
                                <div class="item-title">
                                    <h2 class="text-uppercase title-bar mb-0">{{alergeno.description}}</h2>
                                </div>
                                <div class="item-title">
                                    <img class="icon-allergen" :class="alergeno.toggle ? 'rotate-90': 'rotate-0'" width="22" src="../../assets/chevron-right.svg" alt="icon">
                                </div>
                            </div>
                            <transition name="fade">
                                <div v-if="alergeno.toggle">
                                    <ul class="ms-5 mb-5" style="  list-style-type: circle;">
                                        <li class="fw-light" v-for="(derivative,index) in alergeno.derivatives" :key="index">{{derivative.name}}</li>
                                    </ul>
                                    <!--<div v-show="alergeno.exception">
                                        <h5>Excepciones:</h5>
                                        <p class="text-justify">{{alergeno.exception}}</p>
                                    </div>-->
                                </div>
                            </transition>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default{
    name:'allergensListComponent',
    props:['allergens'],
    setup(props){
        function active_toggle(id){
            //console.log(id)
            props.allergens.map(function(alergeno){
                if(alergeno.id == id){
                    //encontro el id
                    alergeno.toggle = !alergeno.toggle;
                }
            });
        }
      

        return{
            active_toggle,
            
        }
    }

}

</script>