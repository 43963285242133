import { createStore } from 'vuex'
import axios from 'axios'
import {login} from '../store/modules/login'
import moment from 'moment'


export default createStore({
  state: {
    v_categories:[
   {
      "id":36,
      "description":null,
      "enabled":1,
      "title":"entradas",
      "type":"drink",
      "position":1,
      "menu":1,
      "lang":"es",
      "nic_name":"ENTRADAS",
      "nic_lang":"es",
      "nic_id":35,
      
     
   },
   {
      "id":37,
      "description":null,
      "enabled":1,
      
      
      "title":"starters",
      "type":"drink",
      "position":1,
      "menu":1,
      "lang":"en",
      "nic_name":"ENTRADAS",
      "nic_lang":"en",
      "nic_id":36,
     
   },
   {
      "id":8,
      "description":null,
      "enabled":1,
      "photo":null,
      "photo_slug":null,
      "title":"MENU",
      "type":"food",
      "position":6,
      "menu":0,
      "lang":"en",
      "nic_name":"MENUS",
      "nic_lang":"en",
      "nic_id":8,
   
   },
   {
      "id":7,
      "description":null,
      "enabled":1,
      "photo":null,
      "photo_slug":null,
      "title":"MENU",
      "type":"food",
      "position":9,
      "menu":0,
      "lang":"es",
      "nic_name":"MENUS",
      "nic_lang":"es",
      "nic_id":7,
     
   },
   {
      "id":26,
      "description":"Solo disponible de 8:00 am a 12:00 pm",
      "enabled":1,
      
    
      "title":"DESAYUNOS Y BRUNCH",
      "type":"food",
      "position":10,
      "menu":1,
      "lang":"es",
      "nic_name":"BREAKFASTS",
      "nic_lang":"es",
      "nic_id":25,
      
   },
   {
      "id":27,
      "description":"Only available from 8:00 am to 12:00 pm",
      "enabled":1,
      
      
      "title":"BREAKFAST & BRUNCH",
      "type":"food",
      "position":10,
      "menu":1,
      "lang":"en",
      "nic_name":"BREAKFASTS",
      "nic_lang":"en",
      "nic_id":26,
      
   },
   {
      "id":3,
      "description":null,
      "enabled":1,
     
      
      "title":"ENSALADAS",
      "type":"food",
      "position":12,
      "menu":1,
      "lang":"es",
      "nic_name":"ENSALADAS",
      "nic_lang":"es",
      "nic_id":10,
      
   },
   {
      "id":4,
      "description":null,
      "enabled":1,
   
      
      "title":"SALADS",
      "type":"food",
      "position":12,
      "menu":1,
      "lang":"en",
      "nic_name":"ENSALADAS",
      "nic_lang":"en",
      "nic_id":9,
      
   },
   {
      "id":22,
      "description":null,
      "enabled":1,
  
      
      "title":"SOPAS",
      "type":"food",
      "position":20,
      "menu":1,
      "lang":"es",
      "nic_name":"SOPAS",
      "nic_lang":"es",
      "nic_id":21,
     
   },
   {
      "id":23,
      "description":null,
      "enabled":1,
      
      
      "title":"SOUPS",
      "type":"food",
      "position":20,
      "menu":1,
      "lang":"en",
      "nic_name":"SOPAS",
      "nic_lang":"en",
      "nic_id":22,
      
      
   },
   {
      "id":24,
      "description":null,
      "enabled":1,
      
      
      "title":"FONDOS",
      "type":"food",
      "position":30,
      "menu":1,
      "lang":"es",
      "nic_name":"FONDOS",
      "nic_lang":"es",
      "nic_id":23,
      
   },
   {
      "id":25,
      "description":null,
      "enabled":1,
      
      
      "title":"MAIN COURSES",
      "type":"food",
      "position":30,
      "menu":1,
      "lang":"en",
      "nic_name":"FONDOS",
      "nic_lang":"en",
      "nic_id":24,
      
   },
   {
      "id":11,
      "description":null,
      "enabled":1,
      
      
      "title":"WRAPS",
      "type":"food",
      "position":40,
      "menu":1,
      "lang":"es",
      "nic_name":"VARIOS",
      "nic_lang":"es",
      "nic_id":11,
      
   },
   {
      "id":12,
      "description":null,
      "enabled":1,
      
      "title":"WRAPS",
      "type":"food",
      "position":40,
      "menu":1,
      "lang":"en",
      "nic_name":"VARIOS",
      "nic_lang":"en",
      "nic_id":12,
      
   },
   {
      "id":9,
      "description":null,
      "enabled":1,
      
      "title":"PIQUEOS",
      "type":"food",
      "position":50,
      "menu":1,
      "lang":"es",
      "nic_name":"PIQUEOS",
      "nic_lang":"en",
      "nic_id":4,
      
   },
   {
      "id":10,
      "description":null,
      "enabled":1,
      
      
      "title":"SNACKS",
      "type":"food",
      "position":50,
      "menu":1,
      "lang":"en",
      "nic_name":"PIQUEOS",
      "nic_lang":"en",
      "nic_id":4,
      
   },
   {
      "id":17,
      "description":null,
      "enabled":1,
      
      "title":"POSTRES",
      "type":"drink",
      "position":60,
      "menu":1,
      "lang":"es",
      "nic_name":"DESSERTS",
      "nic_lang":"es",
      "nic_id":17,
      
   },
   {
      "id":18,
      "description":null,
      "enabled":1,
      
      "title":"DESSERTS",
      "type":"drink",
      "position":60,
      "menu":1,
      "lang":"en",
      "nic_name":"DESSERTS",
      "nic_lang":"en",
      "nic_id":18,
      
   },
   {
      "id":5,
      "description":"Todas las kombuchas son de nuestra marca Mila & Jacob\u00b4s Brew Kombucha. Son hechos aqu\u00ed mismo de manera artesanal.",
      "enabled":1,
      
      "title":"KOMBUCHAS & JUGOS",
      "type":"drink",
      "position":70,
      "menu":1,
      "lang":"es",
      "nic_name":"KOMBUCHAS",
      "nic_lang":"en",
      "nic_id":5,
      
   },
   {
      "id":6,
      "description":"All of our kombuchas are from Mila & Jacob\u00b4s Brew Kombucha, made here on site in Jacob\u00b4s Brewery.",
      "enabled":1,
      
      "title":"KOMBUCHAS & JUICES",
      "type":"food",
      "position":70,
      "menu":1,
      "lang":"en",
      "nic_name":"KOMBUCHAS",
      "nic_lang":"en",
      "nic_id":5,
      
   },
   {
      "id":1,
      "description":null,
      "enabled":1,
      
      "title":"CERVEZAS ARTESANALES JACOB\u00b4S",
      "type":"drink",
      "position":80,
      "menu":1,
      "lang":"es",
      "nic_name":"CERVEZAS",
      "nic_lang":"es",
      "nic_id":2,
      
   },
   {
      "id":2,
      "description":null,
      "enabled":1,
      
      "title":"JACOB\u00b4S CRAFT BEERS",
      "type":"drink",
      "position":80,
      "menu":1,
      "lang":"en",
      "nic_name":"CERVEZAS",
      "nic_lang":"en",
      "nic_id":1,
      
   },
   {
      "id":13,
      "description":null,
      "enabled":1,
      
      "title":"C\u00f3cteles Mila",
      "type":"drink",
      "position":90,
      "menu":1,
      "lang":"es",
      "nic_name":"COCTELES",
      "nic_lang":"es",
      "nic_id":13,
      
   },
   {
      "id":14,
      "description":null,
      "enabled":1,
      
      "title":"Mila's Cocktails",
      "type":"drink",
      "position":90,
      "menu":1,
      "lang":"en",
      "nic_name":"COCTELES",
      "nic_lang":"en",
      "nic_id":14,
      
   },
   {
      "id":30,
      "description":null,
      "enabled":1,
      
      "title":"Destilados By Mila",
      "type":"drink",
      "position":100,
      "menu":1,
      "lang":"es",
      "nic_name":"DESTILADOS",
      "nic_lang":"es",
      "nic_id":29,
      
    
   },
   {
      "id":31,
      "description":null,
      "enabled":1,
      
      "title":"Spirits by Mila",
      "type":"drink",
      "position":100,
      "menu":1,
      "lang":"en",
      "nic_name":"DESTILADOS",
      "nic_lang":"en",
      "nic_id":30,
      
   },
   {
      "id":32,
      "description":null,
      "enabled":1,
      
      "title":"Bebidas Calientes",
      "type":"drink",
      "position":110,
      "menu":1,
      "lang":"es",
      "nic_name":"BEBIDASCALIENTES",
      "nic_lang":"es",
      "nic_id":31,
      
   },
   {
      "id":33,
      "description":null,
      "enabled":1,
      
      "title":"hot drinks",
      "type":"drink",
      "position":110,
      "menu":1,
      "lang":"en",
      "nic_name":"BEBIDASCALIENTES",
      "nic_lang":"en",
      "nic_id":32,
      
   },
   {
      "id":34,
      "description":null,
      "enabled":1,
      
      "title":"Vinos",
      "type":"drink",
      "position":111,
      "menu":1,
      "lang":"es",
      "nic_name":"VINOS",
      "nic_lang":"es",
      "nic_id":33,
      
   },
   {
      "id":35,
      "description":null,
      "enabled":1,
      
      "title":"wines",
      "type":"drink",
      "position":111,
      "menu":1,
      "lang":"en",
      "nic_name":"VINOS",
      "nic_lang":"en",
      "nic_id":34,
      
   },
   {
      "id":15,
      "description":null,
      "enabled":0,
      
      "title":"COMBOS",
      "type":"food",
      "position":130,
      "menu":1,
      "lang":"es",
      "nic_name":"COMBOS",
      "nic_lang":"es",
      "nic_id":15,
      
   },
   {
      "id":16,
      "description":null,
      "enabled":0,
      
      "title":"COMBOS",
      "type":"food",
      "position":130,
      "menu":1,
      "lang":"en",
      "nic_name":"COMBOS",
      "nic_lang":"en",
      "nic_id":16,
   
     
   }
],
    v_url_global:'https://api-menu.puntoverde.pe/api',//'https://api-menu.puntoverde.pe/api'//'http://127.0.0.1:8000/api'

    v_allergens_es:[
      {
          id:1,
          toggle:false,
          allergen:true,
          name: "Gluten",
          icon_name:"gluten",
          description: "Cereales que contengan gluten",
          derivatives:[
              {name:'Trigo (como trigo espelta y trigo khorasan).'},
              {name:'Centeno.'},
              {name:'Cebada.'},
              {name:'Avena o sus variedades híbridas.'},
              {name:'Productos derivados.'},
          ],
          exception:'Jarabes de glucosa a base de trigo, incluida la dextrosa, maltodextrinas a base de trigo, jarabes de glucosa a base de cebada, cereales utilizados para hacer destilados alcohólicos, incluido el alcohol etílico de origen agrícola.'
      },
      {
          id:2,
          toggle:false,
          allergen:true,
          name: "Mani",
          icon_name:"mani",
          description: "MANI(CACAHUATE) Y PRODUCTOS A BASE DE MANI",
          derivatives:[
              {name:'Todas las variedades de maní y productos a base del mismo.'}],
          exception:null,
      },
       {
          id:3,
          toggle:false,
          allergen:true,
          name: "Soja",
          icon_name:"soja",
          description: "Soja(soya) y productos a base de soja",
          derivatives:[
              {name:'Todas las variedades de soya y productos a base de la misma.'}],
          exception:'A excepción: aceite y grasa de semilla de soja totalmente refinados, tocoferoles naturales mezclados (E306), d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soja, fitosteroles y ésteres de fitosterol derivados de aceites vegetales de soja, ésteres de fitostanol derivados de fitosteroles de aceite de semilla de soja. ',
      },
      {
          id:4,
          toggle:false,
          allergen:true,
          name: "Nuez",
          icon_name:"nuez",
          description: "Frutos cáscara",
          derivatives:[
              {name:'Almendras.'},
              {name:'Avellanas.'},
              {name:'Nueces.'},
              {name:'Anacardos.'},
              {name:'Pecanas.'},
              {name:'Nueces de Brasil.'},
              {name:'Pistachos.'},
              {name:'Productos derivados.'},
              ],
          exception:'A excepción:  frutos de cáscara utilizados para hacer destilados alcohólicos, incluido el alcohol etílico de origen agrícola.',
      },
      {
          id:5,
          toggle:false,
          allergen:true,
          name: "Apio",
          icon_name:"apio",
          description: "Apio y productos derivados",
          derivatives:[
              {name:'Todas las variedades de apio y productos a base del mismo.'},
              ],
          exception:null,
      },
      {
          id:6,
          toggle:false,
          allergen:true,
          name: "Mostaza",
          icon_name:"mostaza",
          description: "Mostaza y productos derivados",
          derivatives:[
              {name:'Todas las variedades de mostaza y productos a base del mismo.'},
              ],
          exception:null,
      },
      {
          id:7,
          toggle:false,
          allergen:true,
          name: "Sésamo",
          icon_name:"sesamo",
          description: "Granos de sésamo y productos a base de granos de sésamo",
          derivatives:[
              {name:'Todas las variedades de sésamo y productos a base del mismo.'},
              ],
          exception:null,
      },
      {
          id:8,
          toggle:false,
          allergen:true,
          name: "Dióxido de azufre y sulfitos",
          icon_name:"e-x",
          description: "Dióxido de azufre y sulfitos",
          derivatives:[
              {name:'Todas las variedades de dióxido de azufre y sulfitos  y productos a base del mismo.'},
              ],
          exception:null,
      },
        {
          id:9,
          toggle:false,
          allergen:true,
          name: "Altramuces",
          icon_name:"altramuces",
          description: "Altramuces o lupinos y produtos a base de estos",
          derivatives:[
              {name:'Todas las variedades de altramuces o lupinos (se encuentran dentro de la categoría de las legumbres) y productos a base del mismo.'},
              ],
          exception:null,
      },
      {
          id:10,
          toggle:false,
          allergen:false,
          name: "Hongos",
          icon_name:"hongo",
          description: "Hongos comestibles",
          derivatives:[
              {name:'Hongos ostra.'},
              {name:'Hongos Champiñon.'},
              {name:'Hongos shiitake.'},
              ],
          exception:null,
      },
      {
          id:11,
          toggle:false,
          allergen:false,
          name: "Ají",
          icon_name:"aji",
          description: "ají",
          derivatives:[
              {name:'Todas las variedades de ají.'},
              ],
          exception:null,
      },
      {
          id:12,
          toggle:false,
          allergen:false,
          name: "Algas",
          icon_name:"algas",
          description: "Algas",
          derivatives:[
              {name:'Todas las variedades de algas.'},
              ],
          exception:null,
      },
      {
          id:13,
          toggle:false,
          allergen:false,
          name: "Leche de coco",
          icon_name:"leche-coco",
          description: "Leche de coco",
          derivatives:[
              {name:'Todas las variedades de leche de coco.'},
              ],
          exception:null,
      },

    ],
    v_allergens_en:[
      {
          id:1,
          toggle:false,
          allergen:true,
          name: "Gluten",
          icon_name:"gluten",
          description: "Cereals containing gluten",
          derivatives:[
              {name:'Wheat (such as spelled and khorasan wheat).'},
              {name:'Rye.'},
              {name:'Barley.'},
              {name:'Oats or their hybrid varieties.'},
              {name:'Derived products.'},
          ],
          exception:'Jarabes de glucosa a base de trigo, incluida la dextrosa, maltodextrinas a base de trigo, jarabes de glucosa a base de cebada, cereales utilizados para hacer destilados alcohólicos, incluido el alcohol etílico de origen agrícola.'
      },
      {
          id:2,
          toggle:false,
          allergen:true,
          name: "Peanut",
          icon_name:"mani",
          description: "PEANUTS AND PEANUT-BASED PRODUCTS",
          derivatives:[
              {name:'All varieties of peanuts and products based on it.'}],
          exception:null,
      },
      {
          id:3,
          toggle:false,
          allergen:true,
          name: "Soy",
          icon_name:"soja",
          description: "Soy and soy-based products",
          derivatives:[
              {name:'All varieties of soy and products based on it.'}],
          exception:'A excepción: aceite y grasa de semilla de soja totalmente refinados, tocoferoles naturales mezclados (E306), d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soja, fitosteroles y ésteres de fitosterol derivados de aceites vegetales de soja, ésteres de fitostanol derivados de fitosteroles de aceite de semilla de soja. ',
      },
      {
          id:4,
          toggle:false,
          allergen:true,
          name: "Nut",
          icon_name:"nuez",
          description: "dried fruits",
          derivatives:[
              {name:'Almonds.'},
              {name:'Hazelnuts.'},
              {name:'Walnuts.'},
              {name:'Cashew nuts.'},
              {name:'Pecans.'},
              {name:'Brazil nuts.'},
              {name:'Pistachios.'},
              {name:'Derived products.'},
              ],
          exception:'A excepción:  frutos de cáscara utilizados para hacer destilados alcohólicos, incluido el alcohol etílico de origen agrícola.',
      },
      {
          id:5,
          toggle:false,
          allergen:true,
          name: "Celery",
          icon_name:"apio",
          description: "Celery and derived products",
          derivatives:[
              {name:'All varieties of celery and products based on it.'},
              ],
          exception:null,
      },
      {
          id:6,
          toggle:false,
          allergen:true,
          name: "Mustard",
          icon_name:"mostaza",
          description: "Mustard and derived products",
          derivatives:[
              {name:'All varieties of mustard and products based on it.'},
              ],
          exception:null,
      },
      {
          id:7,
          toggle:false,
          allergen:true,
          name: "Sesame",
          icon_name:"sesamo",
          description: "Sesame grains and sesame grain products",
          derivatives:[
              {name:'All varieties of sesame and products based on it.'},
              ],
          exception:null,
      },
      {
          id:8,
          toggle:false,
          allergen:true,
          name: "Sulfur dioxide and sulphites",
          icon_name:"e-x",
          description: "Sulfur dioxide and sulphites",
          derivatives:[
              {name:'All varieties of sulfur dioxide and sulphites and products based on the same.'},
              ],
          exception:null,
      },
      {
          id:9,
          toggle:false,
          allergen:true,
          name: "Lupins",
          icon_name:"altramuces",
          description: "lupinis beans and products based on these",
          derivatives:[
              {name:'All varieties of lupinis beans (found within the category of legumes) and products based on it.'},
              ],
          exception:null,
      },
      {
          id:10,
          toggle:false,
          allergen:false,
          name: "Mushrooms",
          icon_name:"hongo",
          description: "Edible mushrooms",
          derivatives:[
              {name:'Mushrooms.'},
              {name:'Oyster mushrooms.'},
              {name:'Shiitake mushrooms.'},
              ],
          exception:null,
      },
      {
          id:11,
          toggle:false,
          allergen:false,
          name: "Chili pepper",
          icon_name:"aji",
          description: "chili pepper",
          derivatives:[
              {name:'All varieties of chili.'},
              ],
          exception:null,
      },
      {
      id:12,
      toggle:false,
      allergen:false,
      name: "Seaweed",
      icon_name:"algas",
      description: "seaweed",
      derivatives:[
          {name:'All varieties of Seaweed.'},
          ],
      exception:null,
      },
      {
          id:13,
          toggle:false,
          allergen:false,
          name: "Coconut milk",
          icon_name:"leche-coco",
          description: "coconut milk",
          derivatives:[
              {name:'All varieties of coconut milks.'},
              ],
          exception:null,
      },
    ],
    v_success:false,
    v_loading:true,
  
  },
  getters: {

    getters_en(state){
      return state.v_categories.filter((category)=>category.lang === 'en' && category.menu && category.enabled);
    },
    getters_es(state){
      return state.v_categories.filter((category)=>category.lang === 'es' && category.menu && category.enabled);
    },

    getters_allergens_es(state){
      return state.v_allergens_es;
    },

    getters_allergens_en(state){
      return state.v_allergens_en;
    }


  },
  mutations: {
    V_CATEGORIES(state,data_categories){
      state.v_categories = data_categories;
      state.v_success = true;
      localStorage.setItem('Categories', JSON.stringify(data_categories));
      state.v_loading = false;//data para cerrar la ventana de loading
    }
  },
  actions: {
    v_action_categories({commit,state}){


        
        state.v_loading = true

        /*
            Revisar si estas en  esta semana para guardar en la cache   
        */
        var now = moment();
        //var input = moment("2022-09-05T11:45:00Z");//desde el 5 empieza la semana
        
        var input = moment().subtract(2, 'd');//desde el 5 empieza la semana
        console.log(now.format(), input.format())
        console.log(now.isoWeek(), input.isoWeek())
        var isThisWeek = (now.isoWeek() == input.isoWeek())
        console.log(isThisWeek,state.v_loading)


        // let local_categories = JSON.parse(localStorage.getItem('Categories'));
        
        // if (local_categories){
        //     console.log('hay categorias guardadas')
        //     return state.v_categories = local_categories
        // }else{
        //     console.log('no hay')

        //     let config = {
        //         headers: {
        //         'Accept':'application/json'
        //         }
        //     }
        //     return axios.get(state.v_url_global+'/index-categories',config)
        //       .then(response => commit('V_CATEGORIES',response.data))
        //       .catch(e => console.log('error'+e));
        // }



        
        let config = {
            headers: {
            'Accept':'application/json'
            }
        }
        return axios.get(state.v_url_global+'/index-categories',config)
          .then(response => commit('V_CATEGORIES',response.data))
          .catch(e => console.log('error'+e));
        


     
    }
  },
  modules: {
    login
  }
})
