<template>
    <div class="fixed-container d-inline-flex btn-lang-en">
        <router-link  :to="{name: 'es'}">
            <button type="submit" class="btn btn-sm btn-outline-light mx-0 rounded-0 border-0">
                <img src="../assets/peru.svg" width="25" alt="">
            </button>
        </router-link>
    </div>
      <!-- rappi and pedidosya -->
    <div class="container-fluid ">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row">
                    <div class="col-md-12 h-bar">
                        <h2 class="title-bar text-center">We are on Pedidos Ya and Rappi.</h2>
                        <small class="mb-2 d-flex justify-content-center fw-light text-normal">Order from 8am to 10pm.</small>
                        <div class="d-flex justify-content-center">
                            <a target="_blank" href="https://www.rappi.com.pe/restaurantes/35241-green-point-carmen-bajo"
                                class="btn btn-outline-dark rounded-0 subtitle fw-light mx-2 text-normal">Rappi</a> <span class="fw-light"> / </span>
                            <a target="_blank" href="https://www.pedidosya.com.pe/restaurantes/cusco/green-point-carmen-bajo-235-menu"
                                class="btn btn-outline-dark rounded-0 subtitle fw-light mx-2 text-normal">PedidosYa</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- alergernos -->
    <allergensComponent
    title="ALLERGEN CONSULTATION"
    subtitle="ALLERGENS LEGEND"
    :allergens="getters_allergens_en"
    ></allergensComponent>

   
    <categorynewComponent
    :categories="getters_en"
    :allergens="getters_allergens_en" 
    id_beer="2"
    id_wine="35"
    btn_name="SEE MORE"
    ></categorynewComponent>
        


</template>
<script>

import { onMounted,computed,onUpdated } from "vue";
import {useStore} from "vuex";
//import categoryComponent from '../components/web/categoryComponent.vue'
import categorynewComponent from '@/components/web/categorynewComponent.vue'
import allergensComponent from "@/components/web/allergensComponent.vue";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'enView',
    components:{
        categorynewComponent,allergensComponent
    },
    setup(){
        const store = useStore();
        const getters_en = computed(() => store.getters.getters_en)
        //const v_categories = 
        onMounted(() => {
            //console.log(getters_en.value.length)
            if (!store.state.v_success){
                store.dispatch('v_action_categories').then(() => {
                
                });//store
            }//if
            

            gsap.to(".btn-lang-en",{
          scrollTrigger: {
          trigger: ".btn-lang-en",
         start: "start+=150 center-=270",
                end: "bottom+=200 bottom",
          //markers: true,
          scrub:1,
          
          },
          opacity:0
          })
        })


        onUpdated(()=>{
          //console.log('lang actualizado')
          
        })


        return{
            store,
            getters_en,
            getters_allergens_en: computed(() => store.getters.getters_allergens_en),
            
        }
    }
}


</script>

<style>

</style>