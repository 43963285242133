import axios from 'axios'

export const login = {
    namespaced: true,
    state: () => ({
        v_user: {},
        v_login:{
          email:null,
          password:null,
        },
        v_users:[],
        
        v_status_api:'',
        v_error_login:false,//para ver si  hay  un error cuando hacemos la peticion
        v_loading_login:{
          status:false,
          message:''
        },
    }),
    mutations: {
        v_user_m(state){
          return state.v_user
        },
        V_USERS(state, data_users) {
          state.v_users = data_users
        },
        V_LOGOUT(state){
          state.v_user = {}
          state.v_login = {
            email:'',
            password:''
          } 
          localStorage.removeItem('UserLogin');
        },
        V_LOGIN(state,data_login){
          state.v_user = data_login;
          localStorage.setItem('UserLogin', JSON.stringify(data_login));
          state.v_status_api = 'success'
        }
    }, 
    actions:{
        v_actions_users({ commit,state,rootState}){
          state.v_loading_login = {status:true,message:'Usuarios'} 
          let config = {
            headers: {
            'Authorization': 'Bearer ' +  state.v_user.accessToken,
            'Accept':'application/json'
              }
          }
          return axios.get(rootState.v_url_global+'/users',config)
            .then(response => {
              state.v_loading_login = {status:false,message:''} 
              commit('V_USERS',response.data)})
            .catch();
        },
        //yourAction ({commit,state}, ac_query){
        v_action_login({commit,state,rootState}){
    
          let user = JSON.parse(localStorage.getItem('UserLogin'));
          //console.log(user)
          state.v_loading_login = {status:true,message:'accesos'} 
         
          if (user && user.accessToken){//y hay un usuario en localstorage hacer la consulta si su token sirve
    
            let config = {
              headers: {
              'Authorization': 'Bearer ' + user.accessToken,
              'Accept':'application/json'
                }
            }
    
            return axios.get(rootState.v_url_global+'/check',config)
              .then(() => {
                //console.log('autorizado'+response.data)
                state.v_user = JSON.parse(localStorage.getItem('UserLogin'));
                state.v_loading_login = {status:false} //finish  loading
                state.v_error_login = false //no  hay error
              })
              .catch(e => {
                console.log('no se puedo conectar con el  token'+e)
                localStorage.removeItem('UserLogin');
                state.v_loading_login = {status:false} //finish  loading
                state.v_error_login = true //notificar que no se puedo ingresar
                }
              );
          }
          else{//caso contrario hacer una consulta con nuevo datos
            return axios.post(rootState.v_url_global+'/login',{
              email:state.v_login.email,
              password:state.v_login.password
              
            })
              .then(response => {
                commit('V_LOGIN',response.data)
                state.v_loading_login = {status:false} //finish  loading
                state.v_error_login = false//falso error
              })  
              .catch(e => { // si hay un error cualquiera mostrar en consola
                console.log('v_error:'+e)
                state.v_status_api = 'error en login'
                state.v_loading_login = {status:false} //finish  loading
                state.v_error_login = true //notificar que no se puedo ingresar 
                
              });
          }
        },
        v_action_logout({commit,state,rootState}){//cerrar sesion de usuario
          let config = {
            headers: {
            'Authorization': 'Bearer ' + state.v_user.accessToken,
            'Accept':'application/json'
            }
        }
    
          return axios.get(rootState.v_url_global+'/logout',config)
            .then(response => {
              commit('V_LOGOUT',null)
              console.log('cerrar sesion'+response);
              localStorage.removeItem('UserLogin');//borrar datos de la cache del  navegador
              state.v_error_login = false//falso error
            })
            .catch();
        },
    
        v_test(data){
          console.log(data)
        }
      },

}