<template>
<div class="content">
  <nav>
    <!-- <router-link to="/es">Es</router-link> |
    <router-link to="/en">En</router-link> -->
  </nav>
  <div class="d-flex justify-content-center bg-greenpoint-dark align-items-center h-5 ">
    <router-link :to="{name: 'home'}">
    <img width="155" src="./assets/gp-white.png" alt="">
    </router-link>
     <div v-if="v_user.user">
    
      <router-link :to="{name: 'home'}" class="btn btn-outline-light rounded-0 mx-2">
          menu
      </router-link>
       <router-link :to="{name: 'login'}" class="btn btn-outline-light rounded-0 mx-2">
          Login
      </router-link>
      
    </div>
    
  </div>
   
  <router-view v-slot="{ Component, route }">
    <transition name="fade" mode="out-in">
      <div :key="route.name">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
  <div class="container-fluid bg-greenpoint-dark footer-gsap text-center">
      <div class="container">
          <div class="row">
              <div class="col-md-2">
                  <div class="row justify-content-center justify-content-md-start"> <div><img src="./assets/gp-white.png" width="150" alt="Green point"></div> </div>
              </div>
              <div class="col-md-7 d-flex justify-content-center mb-3">
                  <div class="d-inline-flex justify-content-center px-2 px-md-2 px-lg-3 pt-3"> <a class="text-white f-quezon text-uppercase" href="https://greenpointcusco.com/home.html">Green point plant based cuisine</a> </div>
                  <!-- <div class="d-inline-flex justify-content-center px-2 px-md-2 px-lg-3 pt-3"> <a class="text-white f-quezon" href="https://greenpoint.meitre.com/">Bookings</a> </div> -->
                  <div class="d-inline-flex justify-content-center px-2 px-md-2 px-lg-3 pt-3"> </div>
              </div>
              <div class="col-md-3 justify-content-center d-flex">
                <a href="/login">
                      <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">
                          <img width="13" src="./assets/user.svg" alt="login">
                      </div>
                  </a>
                  <a href="https://www.facebook.com/GreenPointcusco/">
                      <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">
                          <img width="15px" src="./assets/fb.svg" alt="Facebok de greenpoint">
                      </div>
                  </a>
                    <a href="https://www.instagram.com/greenpointcusco/">
                      <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">
                          <img width="15px" src="./assets/ig.svg" alt="Instagram de greenpoint">
                      </div>
                  </a>
              </div>
          </div>
      </div>
  </div>

</div>

<loading-component></loading-component>

</template>
<script>
import { ref,onMounted,computed } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import loadingComponent from "./components/web/loadingComponent.vue";
//import { gsap } from 'gsap';

export default {
  name:'App',
  components:{loadingComponent},
  setup(){
    const router = useRouter();
    const es_true = ref(true)
    const store = useStore()
    const  v_user = computed(()=>store.state.login.v_user)

    onMounted(() => {
      var ln = window.navigator.language || navigator.browserLanguage;
      ln = ln.substr(0,2)
      
      if(window.location.pathname === "/"){
        if (ln == "es"){
        router.push({name:'es'})
        }
        else if (ln == "en") {
          router.push({name:'en'})
        } else {
        router.push({name:'en'}) 
        } 
      }
      //console.log(router,ln,window.location.pathname)
      if (localStorage.getItem('UserLogin')){
          get_user();
      }
    })


      function get_user(){
            store.dispatch('login/v_action_login')
            .then(() => {//Despues  de enviar datos a vuex evalua si  hay un  error
            //console.log(data)
            if (store.state.login.v_status_api == 'error'){
                //console.log('no hay')
                //toast.error('error')
            }
            else if(store.state.login.v_status_api == 'success'){
                //toast.success('Bienvenido :'+v_user.value.user.name)
            }
            })
            
            
        } 

    return{
      es_true,
      v_user
    }
  }


}

</script>

<style lang="scss"> 
 @import "./scss/style.scss";
  // $color-primary would be defined in that file

.bg-greenpoint-dark{
  background-color: $greenpoint-dark;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

