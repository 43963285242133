<template>
   <div class="container-fluid bg-greenpoint-light-v1">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row">
                    <div class="col-md-8 h-bar">
                        <h2 class="title-bar text-center">{{title}}</h2>
                    </div>
                    <div class="col-md-4 h-bar-1">
                        <a @click="toggle_allergens" class="btn btn-outline-dark rounded-0 subtitle fw-light">{{subtitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="allergens_show">
                <allergensListComponent
                :allergens="allergens"
                ></allergensListComponent>
                
            </div>
        </transition>
    </div>
</template>
<script>
import {ref} from 'vue'
//import {useStore} from "vuex";

import allergensListComponent from './allergensListComponent.vue';

export default{
    name:"allergensComponent",
    props:['title','subtitle','allergens'],
    components:{allergensListComponent},
    setup(){
        //const store = useStore();

        const allergens_show = ref(false)

        function toggle_allergens (){
            allergens_show.value = !allergens_show.value
        }
        return{
            
            allergens_show,
            toggle_allergens
        }
    }
}

</script>