
<template>
    <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5 text-center">
            
            <h5 class="card-title text-center mb-5 fw-light fs-5">Inicio de sesion</h5>
            <form>
                <h1>Bienvenido {{user.user.name}}</h1>
                <!-- <pre>
                    {{user}}
                </pre> -->
                <button class="btn btn-warning" @click.prevent="logout">
                cerrar sesion</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {useStore} from 'vuex'
export default{
    props:['user'],
    setup(){
        const store = useStore();
        function logout(){
            console.log('by');
            store.dispatch('login/v_action_logout')
        }
        
        return{
            logout
        }
    }
}
</script>