<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Greenpoint App"/>
  </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue';//onMounted
//import axios from 'axios';


// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import { useRouter } from 'vue-router';
//import { useStore } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  setup(){
    const router = useRouter();
    //const store = useStore();
    const categories = ref([]);

    // const get_categories = () => {
    //  let config = {
    //         headers: {
    //         'Accept':'application/json'
    //         }
    //     }
    //     axios.get(store.state.v_url_global+'/index-categories',config).then((response) => {
    //     console.log(response.data)
    //     categories.value = response.data
    //     })
    // };

    const es_categories = computed(() => {
      return categories.value.filter((category)=>category.lang === 'es');
    })

    const en_categories = computed(() => {
      return categories.value.filter((category)=>category.lang === 'en');
    })

    onMounted(() => {
      //get_categories();

       var ln = window.navigator.language || navigator.browserLanguage;
      ln = ln.substr(0,2)
      
      if(window.location.pathname === "/"){
        if (ln == "es"){
        router.push({name:'es'})
        }
        else if (ln == "en") {
          router.push({name:'en'})
        } else {
        router.push({name:'en'}) 
        } 
      }
    })
    return{
      //get_categories,
      categories,
      es_categories,
      en_categories,
    }
  }

}
</script>
