<template>

  <div class="d-flex justify-content-center mx-0 container-category-gsap">
    <div class="grid-container">
        <div class="item" v-for="category in categories" :key="category.id" ><!--1 si es del menu-->
            <!-- <img :id="category.id == 36 || category.id == 37?'food':'00'" v-if="category.photo" :class="category.type" class="img-category" :src="category.photo.slug" alt="" style="width:100%"> -->
              <ImageItem
                    :id="(category.id == 36 || category.id == 37)?'food':'00'"
                    :class="category.type" 
                    v-scrollanimation 
                    class="img-category"
                    v-if="category.photo"
                    :source="category.photo.slug"
                ></ImageItem>
            <div :id="(category.id == 5 || category.id == 6)?'drink':'00'" class="border-outline"></div>
            <div class="">
                <div class="text-center">
                    <h2 :id="category.id == 27?'mb-0':''" class="title_menu text-light font-weight-bold" >{{category.title}}</h2>
                    <p class="text-light mb-2" v-if="category.id == 27">
                        <small class="fw-light">Only available from 8:00 am to 12:00 pm</small>
                    </p>
                    <button :disabled="!category.plates?true:false" @click="see_more(category)" class="btn btn-outline-light rounded-0 subtitle_menu fw-light">
                        {{btn_name}}
                    </button>
                </div>
            </div>
        </div>
   </div>
</div>

<div class="container-btns-section">
    <a href="#food" class="btn-fixed-food">
        <img src="../../assets/utensils.svg" alt="">
    </a>
    <a href="#drink" class="btn-fixed-drink">
        <img src="../../assets//champagne-glasses.svg" alt="">
    </a>
</div>


<transition name="fade">
    <div v-if="modal && category_select">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-dialog" role="document" >
                    <div class="modal-content rounded-0">
                        <div class="modal-header">
                            <h5 class="modal-title f-quezon text-uppercase">{{ category_select.title }}</h5>
                            
                            <div class="icon-modal">
                                 <button @click="order_category_prev(category_select.id)" type="button" class="close">
                                    <span aria-hidden="true"> 
                                        <img height="15" src="../../assets/left-long.svg" alt="">
                                    </span>
                                </button>
                                <button @click="order_category_next(category_select.id)" type="button" class="close">
                                    <img height="15" src="../../assets/right-long.svg" alt="">
                                </button>
                                <button @click="toggle_modal" type="button" class="close">
                                    <img height="15" src="../../assets/x.svg" alt="">
                                </button>
                            </div>
                        </div>
                        <transition name="fade">
                            <photo-menu-component 
                            :data_plate="data_plate"
                            :allergens="allergens"
                            @toggle_photo_menu="toggle_photo_menu"
                            v-if="template_photo_menu">  
                            </photo-menu-component>
                        </transition>
                                 
                        <div v-if="v_user.user" class="modal-body"><!-- view for deshabilitie -->
                            <figcaption v-if="category_select.description" class="blockquote-footer mb-0 font-italic font-weight-bold text-muted fw-light">{{ category_select.description }}</figcaption>
                            <div v-for="plate in category_select.all_plates" :key="plate.id">
                                <h5 class="decorated" v-if="plate.subgroup"><span>{{ plate.subgroup }}</span></h5>
                                <figcaption v-if="plate.subgroup_description" class="blockquote-footer">{{plate.subgroup_description}}</figcaption>
                                
                                <p class="plate" :class="plate.ABV || plate.description?'mb-0':''">
                                    <button v-if="plate.photo" style="background:none">
                                        <img @click="toggle_photo_menu(plate),fullScreen()" class="mx-2" width="15" src="../../assets/camera.svg" alt="">
                                    </button>
                                    <span class="descripcion fw-light">{{plate.name}}</span>


                                    <span class="form-switch">
                                        <input 
                                        class="form-check-input" 
                                        type="checkbox" 
                                        true-value="1" 
                                        false-value="0"
                                        v-model="plate.enabled" 
                                        id="flexSwitchCheckDefault"
                                        @change="check(plate.id,plate.category_id)"
                                        >
                                        <!-- <label for="">{{plate.enabled}} <span v-if="plate.enabled">{{plate.enabled}}</span> </label> -->
                                    </span>


                                        <span class="precio"><span v-if="plate.price">s/&nbsp;{{plate.price}}</span>
                                            <span v-show="plate.price && plate.category_id == id_beer"> <!-- id de cerveza 2 -->
                                                <img v-if="plate.price" class="img-unset" src="../../assets/tinta.svg" height="15" alt="">
                                            </span> 
                                            <span v-show="plate.price && plate.category_id == id_wine "> <!--1 id de wines 35 -->
                                                <img v-if="plate.price" class="img-unset" src="../../assets/botelladevino.svg" height="15" alt="">
                                            </span> 
                                            <span v-show="plate.price_tinta && plate.category_id == id_beer">
                                                s/{{ plate.price_tinta }}
                                                <img v-if="plate.price" class="img-unset" src="../../assets/media-tinta.svg" height="15" alt="">
                                            </span>
                                            <span v-show="plate.price_tinta && plate.category_id == id_wine">
                                                s/{{ plate.price_tinta }}
                                                <img class="img-unset" src="../../assets/copadevino.svg" height="15" alt="">
                                            </span> 
                                        </span>    
                                </p>
                                <div>
                                    <div v-if="plate.allergens" class="d-flex" >
                                        <!-- alllergens -->

                                        <div v-for="alergeno in consult_ids(plate.allergens)" :key="alergeno.id" class="item-flex">
                                                <img 
                                                v-on:click="toggle_btn_allergen" 
                                                class="img-icon" 
                                                :src="require('../../assets/icon/' + alergeno.icon_name + '.svg')" 
                                                :alt="alergeno.name" aria-hidden="true" data-toggle="tooltip" 
                                                :title="alergeno.name">

                                                
                                                <transition name="fade">
                                                    <span v-if="btn_allergen">{{alergeno.name}}</span>    
                                                </transition>
                                        </div>
                                        <!-- |.alllergens -->
                                    </div>
                                </div>
                                <small v-show="plate.ABV && plate.IBU" class="span-ABV margin-left-1">
                                    <span v-if="plate.ABV">
                                        ABV: {{plate.ABV}}
                                    </span>
                                    <span v-if="plate.IBU && plate.ABV">
                                        - IBU: {{plate.IBU}}
                                    </span>
                                    
                                </small>
                                <p class="margin-left-1"><small class="fw-lighter">{{plate.description}}</small></p>
                            </div>      
                        </div>
                        <div v-else class="modal-body"><!-- view for client -->
                            <figcaption v-if="category_select.description" class="blockquote-footer mb-0 font-italic font-weight-bold text-muted fw-light">{{ category_select.description }}</figcaption>
                            <div v-for="plate in category_select.plates" :key="plate.id">
                                <h5 class="decorated" v-if="plate.subgroup"><span class="fw-light">{{ plate.subgroup }}</span></h5>
                                <figcaption v-if="plate.subgroup_description" class="blockquote-footer">{{plate.subgroup_description}}</figcaption>
                                
                                <p class="plate" :class="plate.ABV || plate.description?'mb-0':''">
                                    <button v-if="plate.photo" style="background:none">
                                        <img @click="toggle_photo_menu(plate)" class="mx-2" width="15" src="../../assets/camera.svg" alt="">
                                    </button>
                                    <span class="descripcion fw-light">{{plate.name}}</span>
                                
                                    <span class="precio"><span v-if="plate.price">s/&nbsp;{{plate.price}}</span>
                                        <span v-show="plate.price && plate.category_id == id_beer"> <!-- id de cerveza 2 -->
                                            <img v-if="plate.price" class="img-unset" src="../../assets/tinta.svg" height="15" alt="">
                                        </span> 
                                        <span v-show="plate.price && plate.category_id == id_wine "> <!--1 id de wines 35 -->
                                            <img v-if="plate.price" class="img-unset" src="../../assets/botelladevino.svg" height="15" alt="">
                                        </span> 
                                        <span v-show="plate.price_tinta && plate.category_id == id_beer">
                                            s/{{ plate.price_tinta }}
                                            <img v-if="plate.price" class="img-unset" src="../../assets/media-tinta.svg" height="15" alt="">
                                        </span>
                                        <span v-show="plate.price_tinta && plate.category_id == id_wine">
                                            s/{{ plate.price_tinta }}
                                            <img class="img-unset" src="../../assets/copadevino.svg" height="15" alt="">
                                        </span> 
                                    </span>    
                                </p>
                                <div>
                                    <div v-if="plate.allergens" class="d-flex" >
                                        <!-- alllergens -->

                                        <div v-for="alergeno in consult_ids(plate.allergens)" :key="alergeno.id" class="item-flex">
                                            <img v-on:click="toggle_btn_allergen" class="img-icon" :src="require('../../assets/icon/' + alergeno.icon_name + '.svg')" :alt="alergeno.name" aria-hidden="true" data-toggle="tooltip" :title="alergeno.name">
                                            <transition name="fade">
                                                <span v-if="btn_allergen">{{alergeno.name}}</span>    
                                            </transition>
                                        </div>
                                        <!-- |.alllergens -->
                                    </div>
                                </div>
                                <small v-show="plate.ABV && plate.IBU" class="span-ABV margin-left-1">
                                    <span v-if="plate.ABV">
                                        ABV: {{plate.ABV}}
                                    </span>
                                    <span v-if="plate.IBU && plate.ABV">
                                        - IBU: {{plate.IBU}}
                                    </span>
                                    
                                </small>
                                <p class="margin-left-1"><small class="fw-lighter">{{plate.description}}</small></p>
                            </div>      
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>


</template>
<script>

import {ref,onMounted,computed} from 'vue'
import { useStore } from 'vuex';
import axios from 'axios';
import { useToast } from "vue-toastification";

import photoMenuComponent from './photoMenuComponent.vue';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export default{
    name:'categorynewComponent',
    props:['categories','allergens','id_beer','id_wine','btn_name'],
    // props:[
    //     {categories:Array},
    //     {allergens:Array},
    //     {id_beer:String},
    //     {id_wine:String},
    //     {btn_name:String}
    // ],
    components:{
        'photo-menu-component':photoMenuComponent
    },
    setup(props){
        const store = useStore()
        const toast = useToast();
        const v_user = computed(()=>store.state.login.v_user)
        const category_select = ref({})
        const modal = ref(false)
        const btn_allergen = ref(false)
        const template_photo_menu = ref(false)
        const data_plate = ref({})//dato de plate para mostrar la  imagen en  modal
        //const items = ref([])
        const fullScreen = () =>{
            // var elem = document.getElementById("app");
            // if (elem.requestFullscreen) {
            //     elem.requestFullscreen();
            // } else if (elem.webkitRequestFullscreen) { /* Safari */
            //     elem.webkitRequestFullscreen();
            // } else if (elem.msRequestFullscreen) { /* IE11 */
            //     elem.msRequestFullscreen();
            // }

        }
        const toggle_photo_menu = (plate) => {
            
            
            template_photo_menu.value = !template_photo_menu.value;
            if(plate){
                data_plate.value = plate
            }
        }

        const check = (id_plate,id_category) =>{
            console.log(id_category,id_plate)
             let config = {
                    headers: {
                    'Authorization': 'Bearer ' + v_user.value.accessToken,
                    'Accept':'application/json'
                    }
                }
            let category_new = {}
                

            axios.patch(store.state.v_url_global+'/plates-enabled/'+id_plate,{},config).then(()=>{
                store.dispatch('v_action_categories').then(()=>{
                category_new = props.categories.find( cat => cat.id === id_category)
                toast.success("Actualizado");
                //console.log(category_new)
                category_select.value = category_new
            })
            }).catch(()=>{
                store.dispatch('v_action_categories')
                toast.error('Hubo un error')
            })

            
            
        }

        const order_category_prev = (category_id_reserve) => {
           // console.log('category_select:'+category_id_reserve)
            let index_category_reserve 
            let categories_reverse = [];
            categories_reverse = props.categories
            categories_reverse = categories_reverse.slice().reverse()
            //console.log(categories_reverse)
            //console.log(categories_reverse.map((category) => console.log(category)))
            
            categories_reverse.map((category,index)=>{
                if(category.id === category_id_reserve){
                    index_category_reserve = index
                    //console.log('encontrado'+index+'-- id_category:'+category.id)
                    index_category_reserve = index_category_reserve + 1
                    //console.log(index_category_reserve)
                }
                if(index_category_reserve == index){//en el  siguente bucle captura el siguente array
                    //console.log('next'+index)
                    //console.log('category next:'+category.id)
                    category_select.value = category

                }
            })
        }

        const order_category_next = (category_id)=>{

            let index_category 
            //console.log(category_id,props.categories.length)
            props.categories.map((category,index)=>{
                if(category.id == category_id){
                    index_category = index
                    //console.log('encontrado'+index+'_category:'+category.id)
                    index_category = index_category + 1
                    //console.log(index_category)
                }
                if(index_category == index){//en el  siguente bucle captura el siguente array
                    //console.log('next'+index)
                    //console.log('category next:'+category.id)
                    category_select.value = category

                }
            })
           


        }
        function consult_ids(allergens_plate){
            var array = [];
            for (var i = 0; i < props.allergens.length; i++) {
                var igual=false;
                for (var j = 0; j < allergens_plate.length & !igual; j++) {
                    if(props.allergens[i]['id'] == allergens_plate[j]){
                        array.push(props.allergens[i])
                    }
                }
            }
            return array;
        }

        function see_more(category){
            //console.log(category)
            category_select.value = category
            modal.value = true;
        }
        function toggle_modal(){
            modal.value = !modal.value
        }

        function toggle_btn_allergen(){
            btn_allergen.value = !btn_allergen.value
        }

        //onUpdated(() => {
            //console.log('auctualizado')
            //var food0 = document.getElementById('food')

            //const food_div = document.getElementsByClassName("btn-fixed-food")
            //const drink_div = document.getElementsByClassName("btn-fixed-drink")
            //console.log(food0)  
            //food0.food.style.padding = "25px" 
            

            // gsap.to(".btn-fixed-food",{
            // scrollTrigger: {
            //     trigger: ".food",
            //     start: "start center",
            //     end: "bottom top",
            //     //markers: true,
            //     scrub:true,
            //     onEnter: () => {
            //         //food_div[0].classList.add('active');
            //         //drink_div[0].classList.remove('active');
            //         //console.log(food_div[0])
            //         //console.log('enter')
            //     },
            //     //onLeave: () => console.log("leave"),
            //     //onEnterBack: () => console.log("enterBack"),
            //     onLeaveBack: () => {
            //         //food_div[0].classList.remove('active');
            //         //console.log("leaveBack")
            //         },
            //     },
                
                    
            // }
            // )


            // gsap.to(".btn-fixed-drink",{
            // scrollTrigger: {
            //     trigger: ".drink",
            //     start: "start center",
            //     end: "bottom top",
            //     //markers: true,
            //     scrub:true,
            //     onEnter: () => {
            //         //drink_div[0].classList.add('active');
            //         //food_div[0].classList.remove('active');
            //         //console.log(drink_div[0])
            //         //console.log('enter')
            //     },
            //     //onLeave: () => console.log("leave"),
            //     //onEnterBack: () => console.log("enterBack"),
            //     onLeaveBack: () => {
            //         //drink_div[0].classList.remove('active');
            //         //food_div[0].classList.add('active');

            //         //console.log("leaveBack")
            //         },
            //     },
           
                    
            // }
            // )

        //})
        
        onMounted(() => {
            //console.log(items.value)


            gsap.from(".container-btns-section",{
            scrollTrigger: {
                trigger: ".container-category-gsap",
                start: "bottom bottom",
                end: "bottom bottom",
                //markers: true,
                scrub:1,
                },
            opacity: 1,
            x:10,
            //rotation:360,
            duration:3,
            }
            )


            
            

            


        });

        return{
            fullScreen,
            data_plate,
            check,
            template_photo_menu,
            toggle_photo_menu,
            order_category_prev,
            order_category_next,
            see_more,
            category_select,
            modal,
            toggle_modal,
            consult_ids,
            toggle_btn_allergen,
            btn_allergen,
            v_user
        }
    }
}
</script>
<style>


</style>
