<template>
    <div v-if="v_loading" class="container-loading">
        <div class="d-flex align-items-center">
            <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <img src="../../assets/gp-white.png" width="150" alt="">
        </div>
    </div>    
</template>
<script>

import { computed } from '@vue/runtime-core'
import {useStore} from 'vuex'
    


    export default{
        

        setup(){
            const store = useStore()
            const v_loading = computed(()=>store.state.v_loading)
            
          
            return{
                v_loading
            }
        }
    }

</script>
<style>


    /*#19282b*/
</style>
