<template>
    <div class="fixed-container d-inline-flex btn-lang-es">
        <router-link :to="{name: 'en'}">
            <button type="submit" class="btn btn-sm btn-outline-light mx-0 rounded-0 border-0">
                <img src="../assets/estados-unidos.svg" width="25" alt="">
            </button>
        </router-link>
    </div>
    <!-- rappi and pedidosya -->
    <div class="container-fluid ">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row">
                    <div class="col-md-12 h-bar">
                        <h2 class="title-bar text-center">Ya estamos en PedidosYa y Rappi.</h2>
                        <small class="mb-2 d-flex justify-content-center text-normal">Haz tu delivery de 8am a 10pm.</small>
                        <div class="d-flex justify-content-center">
                            <a target="_blank" href="https://www.rappi.com.pe/restaurantes/35241-green-point-carmen-bajo"
                                class="btn btn-outline-dark rounded-0 subtitle mx-2 text-normal">Rappi</a> <span class="fw-light"> / </span> 
                            <a target="_blank" href="https://www.pedidosya.com.pe/restaurantes/cusco/green-point-cusco-ef2ac42f-cadf-471f-8420-f459d506b664-menu"
                                class="btn btn-outline-dark rounded-0 subtitle mx-2 text-normal">PedidosYa</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <!-- alergernos -->
    <allergensComponent
    title="CONSULTA ALÉRGENOS"
    subtitle="LEYENDA DE ALÉRGENOS"
    :allergens="getters_allergens_es"
    ></allergensComponent>


    <categorynewComponent
    v-if="!store.v_success"
    :categories="getters_es" 
    :allergens="getters_allergens_es" 
    id_beer="1" 
    id_wine="34"
    btn_name="VER MÁS"
    ></categorynewComponent>
    

</template>
<script>

import { onMounted,computed, onUpdated } from "vue";
import {useStore} from "vuex";

import categorynewComponent from '@/components/web/categorynewComponent.vue'
import allergensComponent from "@/components/web/allergensComponent.vue";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


export default {
    name: 'esView',
    components:{
        allergensComponent,categorynewComponent
    },
    setup(){
        const store = useStore();

        const getters_es = computed(() => store.getters.getters_es)
        //const v_categories =
        onMounted(() => {
            //console.log(getters_es.value.length)
            if (!store.state.v_success){
                store.dispatch('v_action_categories').then(() => {
               
                });//store
            }//if

            gsap.to(".btn-lang-es",{
            scrollTrigger: {
                trigger: ".btn-lang-es",
                start: "start+=150 center-=270",
                end: "bottom+=200 bottom",
                //markers: true,
                scrub:1,
                
            },
            opacity:0
            })

        })

        onUpdated(()=>{
          //console.log('lang actualizado')
        

         

        })

        return{
            store,
            getters_es,
            getters_allergens_es: computed(() => store.getters.getters_allergens_es),
        }
    }
}


</script>