import "bootstrap/dist/css/bootstrap.min.css"


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ImageItem from './components/web/ImageItem.vue'
import LazyLoadDirective from "./directives/LazyLoadDirective"
import scrollanimation from "./directives/scrollanimation"
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here TOAST
};


import "bootstrap/dist/js/bootstrap.js"
import './registerServiceWorker'


const app = createApp(App)
.component('ImageItem',ImageItem)
.directive('lazyload',LazyLoadDirective)//directive
.directive('scrollanimation',scrollanimation)//directive
.use(store)
.use(router)
.use(VueAxios, axios)


app.mount('#app')
app.use(Toast, options);


